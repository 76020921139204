// extracted by mini-css-extract-plugin
export var marginBottom = "fields-module--marginBottom--3+o1Q";
export var fieldWrapper = "fields-module--fieldWrapper--EOxgB";
export var field = "fields-module--field--nONOe";
export var white = "fields-module--white--vptEq";
export var grey = "fields-module--grey--JaS4B";
export var notchedOutline = "fields-module--notchedOutline--qBkOv";
export var acceptmentWrapper = "fields-module--acceptmentWrapper--CdWi-";
export var acceptment = "fields-module--acceptment--CmUXb";
export var acceptmentContent = "fields-module--acceptmentContent--XDwCu";
export var newYear = "fields-module--newYear--Od8IQ";
export var halvaDlyaGamerov = "fields-module--halvaDlyaGamerov--aVT4y";
export var acceptmentLabel = "fields-module--acceptmentLabel--zH4tq";
export var acceptmentLabelRoot = "fields-module--acceptmentLabelRoot--OQ7qD";
export var acceptmentLink = "fields-module--acceptmentLink--sAM99";
export var acceptmentError = "fields-module--acceptmentError--LB5fu";