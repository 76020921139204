import { boolean, mixed, object, string } from "yup"
import {
  isCyrillic,
  isNumberOrSpecChars,
  isNumberOrSpecCharsWithoutSpace,
  isItMoreThanTwoWords,
  isMaxOfOneHyphen,
  phoneRegExp,
} from "../../../helpers/formHelpers"

export const PersonalFormSchema = object().shape({
  phone: string()
    .matches(phoneRegExp, "Пожалуйста, укажите корректный номер")
    .required("Введите номер телефона полностью"),
  fio: object().shape({
    data: object().shape({
      surname: mixed<any>()
        .required("Введите фамилию и имя через пробел")
        .test(
          "surname isMaxOfOneHyphen",
          "Неверно введена фамилия",
          (value) => !isMaxOfOneHyphen(value)
        )
        .test(
          "surname isNumberOrSpecChars",
          "Фамилия не должна содержать символы или числа",
          (value) => !isNumberOrSpecChars(value)
        )
        .test("surname isCyrillic", "Фамилия не должна содержать латиницу", (value) =>
          isCyrillic(value)
        ),
      name: mixed<any>()
        .required("Введите фамилию и имя через пробел")
        .test("name isMaxOfOneHyphen", "Неверно введено имя", (value) => !isMaxOfOneHyphen(value))
        .test(
          "name",
          "Имя не должно содержать символы или числа",
          (value) => !isNumberOrSpecChars(value)
        )
        .test("name isCyrillic", "Имя не должно содержать латиницу", (value) => isCyrillic(value)),
      patronymic: mixed<any>()
        .test(
          "patronymuic isItMoreThanTwoWords",
          "Отчество не должно состоять более чем из двух слов",
          (value) => !isItMoreThanTwoWords(value)
        )
        .test(
          "patronymic",
          "Отчество не должно содержать символы или числа",
          (value) => !isNumberOrSpecCharsWithoutSpace(value?.trim())
        )
        .test("patronymic isCyrillic", "Отчество не должно содержать латиницу", (value) =>
          isCyrillic(value)
        ),
    }),
  }),

  agree: boolean().required().oneOf([true], "Необходимо согласие с условиями"),
})
