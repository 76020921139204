import React, {
  ChangeEvent,
  Dispatch,
  FocusEventHandler,
  ReactElement,
  SetStateAction,
} from "react"
import { FormikTouched } from "formik"

export type TFormVariant =
  | "standart"
  | "newYear"
  | "kiberponedelnik"
  | "halvaDlyaGamerov"
  | "btsAuto"
  | "credit"

export type IFormOrderProps = {
  title?: React.ReactNode
  dataLayerName?: string
  formBtnText?: string
  orderNum?: string
  longTitle?: boolean
  additionalEventInDataLayer?: boolean
  longForm?: boolean
  backgroundColor?: string
  subTitle?: string | ReactElement
  emptyForm?: boolean
  onlyPKWPage?: boolean
  isGreenApprove?: boolean
  variant?: TFormVariant
  fioPlaceHolder?: string
  backgroundSection?: string
  hasSelect?: boolean
  hasCityWithOffices?: boolean
  thanksPage?: TThanksPage
  inputsColor?: "grey" | "white"
}

export const THANKS_PAGE = {
  SUCCESS: "/success/",
} as const

export type TThanksPage = typeof THANKS_PAGE[keyof typeof THANKS_PAGE]

export interface FioData {
  surname: string | null
  name: string | null
  patronymic: string | null
}

export interface FormValues {
  phone: string
  fio: {
    data: FioData
  }
  agree: boolean
}

export type IPersonalForm = {
  dataLayerName?: string
  onSubmit?: (value: Record<string, any>) => void
  formBtnText?: string
  noVerify?: boolean
  longForm?: boolean
  productName?: string
  inputsColor?: "white" | "grey"
  fullWidth?: boolean
  onlyPKWPage?: boolean
  isGreenApprove?: boolean
  variant?: TFormVariant
  thanksPage?: TThanksPage
  selectItems?: Record<"value" | "label", string>[]
  selectTitle?: string
}

export interface Response {
  requestId: string
  status: "pending" | "repeated" | "approve" | "reject" | "error"
  partnerExternalId: number
}

export type TFioField = {
  isGreenApprove?: boolean
  type: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  touched?: FormikTouched<{}>
  errors?: Record<string, any>
  id: string
  name: string

  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  inputsColor?: "white" | "grey"
  fioPlaceHolder?: string
  fioValue?: {
    data: FioData
  }
  setFocusPhone: Dispatch<SetStateAction<boolean>>
}

export type TPhoneField = {
  focus: boolean
  setFocus: Dispatch<SetStateAction<boolean>>
  id: string
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPaste: (e: React.ClipboardEvent) => void
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  error?: string
  touched?: FormikTouched<{}>
  value: string
  inputsColor?: "white" | "grey"
  isFioFieldValidate?: boolean
  isGreenApprove?: boolean
}
