// extracted by mini-css-extract-plugin
export var section = "formOrder-module--section--9Yrcx";
export var emptyForm = "formOrder-module--emptyForm--xGBjB";
export var backgroundSection = "formOrder-module--backgroundSection--djWcQ";
export var newYear = "formOrder-module--newYear--7E8TQ";
export var sectionLongTitle = "formOrder-module--sectionLongTitle--z2Mp4";
export var sectionWithTimer = "formOrder-module--sectionWithTimer--VDxeQ";
export var form = "formOrder-module--form--wzvOs";
export var backgroundColor = "formOrder-module--backgroundColor--Mj4sq";
export var formLongTitle = "formOrder-module--formLongTitle--+armV";
export var info = "formOrder-module--info--GICA1";
export var infoLongTitle = "formOrder-module--infoLongTitle--GznXX";
export var head = "formOrder-module--head--wg72N";
export var headLongTitle = "formOrder-module--headLongTitle--Mu0qb";
export var descr = "formOrder-module--descr--l-UAE";
export var descrLongTitle = "formOrder-module--descrLongTitle--ZNpcy";
export var formContainer = "formOrder-module--formContainer--5Z182";
export var img = "formOrder-module--img--JUC06";
export var anchor = "formOrder-module--anchor--TS6E7";
export var timer = "formOrder-module--timer--efagY";
export var halvaDlyaGamerov = "formOrder-module--halvaDlyaGamerov--8QcLU";
export var btsAuto = "formOrder-module--btsAuto--2oY4j";
export var credit = "formOrder-module--credit--dVIkV";