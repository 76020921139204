import { pushToDataLayer } from "@ecom/ui/utils/pushToDataLayer"
import { events } from "./events"

const API = process.env.GATSBY_API_URL || "https://api-app.sovcombank.ru"

export const sendRequest = (
  url = "/v2/app/public/start",
  data: Record<string, any>,
  phoneValidate = false
) =>
  fetch(`${API}${url}`, {
    method: phoneValidate ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(`HTTP error. Status code: ${res.status}`)
      }

      return res.json()
    })
    .catch((err) => {
      console.error("error while sending request:", err)

      pushToDataLayer(events.GAFormEvent.error)

      return Promise.reject(err)
    })
