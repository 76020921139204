import React from "react"
import { Helmet } from "react-helmet"

import LayoutOrder from "../../../components/Layout/LayoutOrder"
import { FormOrder } from "../../../components/FormOrder"

export default function FormOrderFrame() {
  return (
    <LayoutOrder>
      <Helmet defer>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <FormOrder title="Оформите карту" dataLayerName="shortPersonalForm" orderNum="7" longForm />
    </LayoutOrder>
  )
}
