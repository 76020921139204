import React, { useEffect, useContext, useState } from "react"
import { useFormik } from "formik"
import { navigate } from "gatsby"
import clsx from "clsx"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import {
  pushToDataLayerInvalidField,
  pushToDataLayerLoadedStep,
  pushToDataLayerSendForm,
  pushToDataLayerStepSuccess,
} from "@ecom/ui/utils/pushToDataLayerUtils"

import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import Button from "@material-ui/core/Button"
import { PhoneField } from "./fields/PhoneField"
import { FioField } from "./fields/FioField"
import { AcceptmentField } from "./fields/AcceptmentField"

import { setIDBValue } from "../../../utils/idbUtils"

import { events } from "../../../helpers/events"
import { CalcWithFormContext } from "../../../context"

import { PersonalFormSchema } from "./schema"

import * as styles from "./personalForm.module.scss"
import { FormValues, IPersonalForm, THANKS_PAGE, Response } from "../types"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"

import { sendRequest } from "../../../helpers/sendRequest"

export const PersonalForm = ({
  dataLayerName = "shortPersonalForm",
  onSubmit,
  formBtnText = "Получить карту",
  noVerify = false,
  longForm = false,
  productName = "КК Халва",
  inputsColor = "white",
  fullWidth,
  variant = "standart",
  isGreenApprove,
  thanksPage = THANKS_PAGE.SUCCESS,
}: IPersonalForm) => {
  const { loanAmount, term } = useContext(CalcWithFormContext)
  const [focusPhone, setFocusPhone] = useState(false)

  const defaultSubmit = (valuesToRequest: Record<string, any>) => {
    sendRequest(`/v2/app/public/start`, { ...valuesToRequest, phoneValidate: !noVerify })
      .then(({ status, requestId }: Response) => {
        if (["pending", "repeated", "reject", "waitingDecision", "approve"].includes(status)) {
          setIDBValue("requestId", requestId)
          return Promise.resolve(status)
        }

        return Promise.reject(new Error(status))
      })
      .then((status) => {
        const { approve, reject, repeated } = events.applicationSend

        switch (status) {
          case "reject":
            navigate("/frame/form-order-frame/error-reject")
            pushToDataLayer(reject)
            return
          case "repeated":
            navigate("/frame/form-order-frame/error-repeat")
            pushToDataLayer(repeated)
            return
          case "error":
            throw Error(status)
          case "approve":
            pushToDataLayer(approve)
            break
          default:
            {
              const formSentMessage = JSON.stringify({ action: "FORM_SENT" })
              window.parent.postMessage(formSentMessage, "*")
            }
            break
        }
        if (noVerify) {
          navigate("/frame/form-order-frame/success")
        } else {
          navigate("/frame/form-order-frame/verify", {
            state: { haveCourier: longForm, thanksPage },
          })
        }
      })
      .catch((e: Error) => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        console.error(e, "error is sendRequest")

        navigate("/frame/form-order-frame/error-technical")
      })
      .finally(() => {
        pushToDataLayerStepSuccess(dataLayerName)
      })
  }

  const handleClick = (errors: object) => {
    // нужен для отправки ошибок при повторном нажатии button submit
    Object.entries(errors).forEach((fieldKey) => {
      const [fieldName, errorMessage] = fieldKey
      if (fieldName === "fio") {
        const сonvertErrorMessage = Object.entries(errorMessage.data)
          .map(([, surname]) => surname)
          .join(",")
        pushToDataLayerInvalidField(fieldName, сonvertErrorMessage, true)
      } else {
        pushToDataLayerInvalidField(fieldName, errorMessage, true)
      }
    })
    pushToDataLayerSendForm()
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      phone: "",
      fio: { data: { surname: null, name: null, patronymic: null } },
      agree: true,
    },
    validationSchema: PersonalFormSchema,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      const valuesToRequest = {
        agree: values.agree,
        surname: values.fio.data.surname,
        name: values.fio.data.name,
        patronymic: values.fio.data.patronymic,
        phone: values.phone.replace(/[- )(+]/g, ""),
        productName,
        additionalData: getAdditionalData(),
        sum: loanAmount,
        term,
      }

      setSubmitting(true)

      setIDBValue("phone", valuesToRequest.phone)

      if (!onSubmit) {
        defaultSubmit(valuesToRequest)
      } else {
        onSubmit(valuesToRequest)
      }
    },
  })

  useEffect(() => {
    if (dataLayerName) {
      pushToDataLayerLoadedStep(dataLayerName)
    }
  }, [dataLayerName])

  const handleOnPastePhoneField = (e: React.ClipboardEvent<Element>) => {
    let phone = e.clipboardData.getData("text").replace(/\D/g, "")
    if (!phone) return
    if (phone.length === 11) {
      phone = phone.slice(1)
    }
    formik.setFieldValue("phone", phone)
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <div
          onClick={() => handleClickForm}
          onKeyDown={() => handleClickForm}
          role="button"
          tabIndex={0}
        >
          <FioField
            handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
            fioValue={formik.values.fio}
            errors={formik.errors.fio}
            touched={formik.touched.fio}
            name="fio"
            id="fio"
            type="fio"
            inputsColor={inputsColor}
            isGreenApprove={isGreenApprove}
            fioPlaceHolder="ФИО"
            setFocusPhone={setFocusPhone}
          />
          <PhoneField
            focus={focusPhone}
            setFocus={setFocusPhone}
            onChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.errors.phone}
            touched={formik.touched.phone}
            isFioFieldValidate={Boolean(!formik.errors.fio)}
            name="phone"
            id="phone"
            inputsColor={inputsColor}
            isGreenApprove={isGreenApprove}
            onPaste={(e) => handleOnPastePhoneField(e)}
          />
        </div>

        <AcceptmentField
          value={formik.values.agree}
          onChange={formik.handleChange}
          error={formik.errors.agree}
          name="agree"
          id="agree"
          touched={formik.touched.agree}
          variant={variant}
        />
        <Button
          classes={{ root: clsx(styles.btn, { [styles.btnWidth]: !fullWidth }, styles[variant]) }}
          type="submit"
          disabled={formik.isSubmitting}
          onClick={() => handleClick(formik.errors)}
          fullWidth={fullWidth}
        >
          {formBtnText}
        </Button>
      </form>
    </div>
  )
}
